
//// LOCAL
//export const base_url = 'http://localhost:1338' 
//export const img_base_url = 'http://192.168.10.49:1338'  //IMAGE URL FOR SAILS SERVER

//// JDD TEST
////export const base_url = 'https://resto-back.evolutiosystem.com' 
//export const img_base_url = 'https://resto-back.evolutiosystem.com'  //IMAGE URL FOR SAILS SERVER

//// JDD TEST LOGI PRL
//export const base_url = 'https://jdd-back.logicielperlene.com' 
//export const img_base_url = 'https://jdd-back.logicielperlene.com'  //IMAGE URL FOR SAILS SERVER

//// PRL DG DEMO 
//export const base_url = 'https://resto-back.evolutiosystem.com'
//export const img_base_url = 'https://resto-back.evolutiosystem.com'  //IMAGE URL FOR SAILS SERVER

//// FATOU FROZEN
//export const base_url = 'https://fatou-back.evolutiosystem.com' 
//export const img_base_url = 'https://fatou-back.evolutiosystem.com'  //IMAGE URL FOR SAILS SERVER




//export const base_url = ' https://jdd-perlene.herokuapp.com' https://resto-back.evolutiosystem.com 

// const base_url = ' https://jdd-test.herokuapp.com'
//export const base_url ='http://logicielperlene.com/app'

//////export const img_base_url = 'http://192.168.10.49:5000'  //IMAGE URL FOR EXPRESS
///export const img_base_url = 'https://resto-img.evolutiosystem.com'  //IMAGE URL FOR ONLINE SERVER


/// ENVIRONNEMENT REEL

//// FATOU FROZEN
//export const base_url = 'https://back-fatou.perlenedg.com' 
//export const img_base_url = 'https://back-fatou.perlenedg.com'  //IMAGE URL FOR SAILS SERVER

//// LA MARTINE
//export const base_url = 'https://martine-back.evolutiosystem.com' 
//export const img_base_url = 'https://martine-back.evolutiosystem.com'  //IMAGE URL FOR SAILS SERVER

//// AGRO DYNAMIQUE
//export const base_url = 'https://agrodynamique-back.perlenedg.com' 
//export const img_base_url = 'https://agrodynamique-back.perlenedg.com'  //IMAGE URL FOR SAILS SERVER

//// LOLO ANDOCHE
//export const base_url = 'https://loloandoche-back.perlenedg.com' 
//export const img_base_url = 'https://loloandoche-back.perlenedg.com'  //IMAGE URL FOR SAILS SERVER

//// HELLO PHONE
//export const base_url = 'https://hellophone-back.perlenedg.com' 
//export const img_base_url = 'https://hellophone-back.perlenedg.com'  //IMAGE URL FOR SAILS SERVER

//// OLOYEDE MARKET
//export const base_url = 'https://oloyedemarket-back.perlenedg.com' 
//export const img_base_url = 'https://oloyedemarket-back.perlenedg.com'  //IMAGE URL FOR SAILS SERVER

//// GOD GRACE DISTRIBUTION
//export const base_url = 'https://ggd-back.perlenedg.com' 
//export const img_base_url = 'https://ggd-back.perlenedg.com'  //IMAGE URL FOR SAILS SERVER


//// STATION CONTINUE
//export const base_url = 'https://stationcontinue-back.perlenedg.com' 
//export const img_base_url = 'https://stationcontinue-back.perlenedg.com'  //IMAGE URL FOR SAILS SERVER

//// HALAL
//export const base_url = 'https://halal-back.perlenedg.com/' 
//export const img_base_url = 'https://halal-back.perlenedg.com/'  //IMAGE URL FOR SAILS SERVER

//// MZ DISTRIBUTION
//export const base_url = 'https://mzdistribution-back.perlenedg.com' 
//export const img_base_url = 'https://mzdistribution-back.perlenedg.com'  //IMAGE URL FOR SAILS SERVER


//// DONAN LAKSHMI
//export const base_url = 'https://donanlakshmi-back.perlenedg.com' 
//export const img_base_url = 'https://donanlakshmi-back.perlenedg.com'  //IMAGE URL FOR SAILS SERVER

//// BR ABIALA https://prldg-back.perlenerestaurant.com/
//export const base_url = 'https://br-abiala-back.perlenedg.com' 
//export const img_base_url = 'https://br-abiala-back.perlenedg.com'  //IMAGE URL FOR SAILS SERVER

//// NEW PRL DG /
//export const base_url = 'https://prldg-back.perlenerestaurant.com' 
//export const img_base_url = 'https://prldg-back.perlenerestaurant.com'  //IMAGE URL FOR SAILS SERVER

//// NEW FATOU FROZEN /
//export const base_url = 'https://perlenedg-back.fatoufrozen.com' 
//export const img_base_url = 'https://perlenedg-back.fatoufrozen.com'  //IMAGE URL FOR SAILS SERVER

//// NOSITO /
export const base_url = 'https://nosito-back.perlenedg.com' 
export const img_base_url = 'https://nosito-back.perlenedg.com'  //IMAGE URL FOR SAILS SERVER

// PARAMETRES

export const use_logiciel = 'YES' // YES or NO 
export const is_GestionMesure = 'NO' // YES or NO 